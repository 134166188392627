.Container {
  display: grid;
  height: 100vh;
  z-index: -1000;
  grid-template-columns: 525px minmax(0, 1fr);
  grid-template-rows: 194px minmax(0, 1fr);
  width: 100vw;
  overflow: auto;
}
/* small display-phones */
@media only screen and (max-width: 1023px) and (max-height: 896px) {
  .Container {
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 70vh 30vh;
    height: 100vh;
  }
}
/* big-tablets */
@media only screen and (max-width: 1023px) and (min-height: 897px) {
  .Container {
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 70vh 30vh;
    height: 100vh;
  }
}

/* Desktops, large screens */
@media only screen and (max-width: 1280px) and (min-width: 1024px) and (max-height: 896px) {
  .Container {
    display: grid;
    grid-template-columns: 50vw 50vw;
    grid-template-rows: 30vh 70vh;
    height: 100vh;
    width: 100vw;
  }
}
/* Ipad pro */
@media only screen and (max-width: 1280px) and (min-width: 1024px) and (min-height: 897px) {
  .Container {
  }
}
@media only screen and (min-width: 1280px) and (min-height: 897px) {
  .Container {
  }
}
@media only screen and (max-width: 600px) {
  .Container {
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: 70vh 30vh;
    height: 100vh;
  }
}
