.Container {
  position: relative;
  width: auto;
}

.MenuContainer {
  background: white;

  position: absolute;
  top: 54px;

  padding: 20px;

  width: 250px;

  border: 1px solid rgba(118, 118, 118, 0.28);
  border-radius: 13px;

  box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 37px 0px;
}

.MenuContainer > * {
  margin: 10px 0;
}

.MenuContainer > h3:nth-of-type(2) {
  margin-top: 24px;
}

.FilterContainer {
  margin: 8px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ClearFilter {
  background: white;
  font-weight: 400;
  padding: 10px 20px;
  border: 2px solid #dddddd;
  border-radius: 30px;
  outline: none;
  min-width: 50px;
}

.ClearFilter:hover {
  cursor: pointer;
}

.ClearFilter:disabled {
  cursor: not-allowed;
}
