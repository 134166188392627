:root {
  --primary-color: #7895ff;
}

/* HTML5 reset */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Font and typeface */
* {
  font-family: "Hind", sans-serif;
}

h1 {
  font-size: 32px;
  font-weight: 700;
  margin: 8px 16px;
}

h2 {
  font-size: 20px;
  font-weight: 700;
  margin: 8px 16px;
}

h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 8px 16px;
}

h4 {
  font-size: 14px;
  font-weight: 400;
}

/* ::-webkit-scrollbar {
	width: 13px;
	
	
  }
  ::-webkit-scrollbar-button {
	
  }
  ::-webkit-scrollbar-thumb {
	background: #f9f7f1;
	border: 0px none #444141;
	
  }
  ::-webkit-scrollbar-thumb:hover {
	background: #666666;
  }
  ::-webkit-scrollbar-thumb:active {
	background: #000000;
  }
  ::-webkit-scrollbar-track {
	
	border: 0px none #ffffff;
	
	
  }
  ::-webkit-scrollbar-track:hover {
	
  }
  ::-webkit-scrollbar-track:active {
	
  }
  ::-webkit-scrollbar-corner {
	background: transparent; 
  }*/
