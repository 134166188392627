.Container {
  background: white;
  box-sizing: border-box;

  /*box-shadow: 0 -2px 4px 0 ;*/
  /* This is not working in Mozilla */
  border-right: rgba(0 0 0 /0.1) ridge 2px;
  padding: 11px 19px 0 0px;
  width: 525px;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1300px) {
}

.Header {
  margin-bottom: 16px;
}

.Header a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: black;
}

.ArticleSection {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: scroll;
  padding-right: 30px;
  margin-left: 19px;
}
/* width */
.ArticleSection::-webkit-scrollbar {
  width: 7px;
  border-radius: 30px;
}

/* Track */
.ArticleSection::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 30px;
}

/* Handle */
.ArticleSection::-webkit-scrollbar-thumb {
  background: rgba(0 0 0 /0.1);
  border-radius: 30px;
}

/* Handle on hover */
.ArticleSection::-webkit-scrollbar-thumb:hover {
  background: #b4b4b4;
}

.ArticleSection > div:not(:first-child) {
  margin: 0 0;
}

.PageSection {
  min-height: 40px;
  height: 55px;
  width: 475px;
  position: relative;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.PageSection > span {
  padding: 5px 8px;
  margin: 0px 4px;
  border-radius: 8px;
  cursor: none;
}

.PageSection > span:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.PageSection .Selected {
  background-color: #f2f2f2;
}

.PageSection .Disabled {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #b4b4b4;
  cursor: default !important;
}

@media only screen and (max-width: 1024px) {
}
@media only screen and (max-width: 1023px) and (max-height: 896px) {
  .Container {
    grid-column: 1/2;
    grid-row: 2/3;
    width: 100%;
    height: 30vh;
  }
  .PageSection {
    height: 10vh;
    width: 100%;
    padding: 17px 0;
  }
}
/* big-tablets */
@media only screen and (max-width: 1023px) and (min-height: 897px) {
  .Container {
    grid-column: 1/2;
    grid-row: 2/3;
    width: 100%;
    height: 30vh;
  }
  .PageSection {
    height: 10vh;
    width: 100%;
    padding: 17px 0;
  }
}
/* Desktops, large screens */
@media only screen and (max-width: 1280px) and (min-width: 1024px) and (max-height: 896px) {
  .Container {
    width: 50vw;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1024px) and (min-height: 897px) {
  .Container {
  }
  .ArticleSection {
    margin-top: 5vh;
    overflow: hidden;
  }
  .PageSection {
    padding: 17px 0;
    position: absolute;
    bottom: 1vh;
    left: 0;
  }
}
@media only screen and (min-width: 1280px) and (min-height: 897px) {
  .Container {
  }
}

/* @media only screen 
  and (min-device-width: 721px) 
  and (max-device-width: 1024px) {
    .Container{
      grid-column: 1/2;
      grid-row: 2/3;
      width:100%;
    
     
  
    }
    .PageSection{
      height: 10vh;
      width: 100%;
      padding: 17px 0;
    }
  } */
