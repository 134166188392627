.Container {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 4px;
}

.Container:hover {
  background: #f2f2f2;
  cursor: pointer;
}

.Container:active {
  background: #e4e4e4;
  cursor: pointer;
}

.Selected {
  background: #f2f2f2;
}

.Text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50px;
}

.Text h4 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
}

.subHeading {
  display: flex;
  flex-direction: row;
}

.subHeading > p {
  color: var(--primary-color);
  font-size: 12px;
}

.subHeading > img {
  height: 10px;
  width: 10px;
  margin-right: 8px;
}

.ImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  min-height: 55px;
  min-width: 55px;
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) and (min-height: 897px) {
  .Container {
    margin: 20px 15px;
  }
}
@media only screen and (max-width: 1023px) and (min-height: 897px) {
  .Container {
  }
}
