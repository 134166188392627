.Map {
  z-index: 1;
  height: 100vh;
  grid-row: 1/3;
  grid-column: 2;
}

@media only screen and (max-width: 1023px) and (max-height: 896px) {
  .Container {
  }
  .Map {
    z-index: 10;
    width: 100vw;
    border-bottom: rgba(0 0 0 /0.1) ridge 1px;
    border-top: rgba(0 0 0 /0.1) ridge 1px;
    height: 70vh;
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
/* big-tablets */
@media only screen and (max-width: 1023px) and (min-height: 897px) {
  .Map {
    z-index: 10;
    width: 100vw;
    border-bottom: rgba(0 0 0 /0.1) ridge 1px;
    border-top: rgba(0 0 0 /0.1) ridge 1px;
    height: 70vh;
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
/* Desktops, large screens */
@media only screen and (max-width: 1280px) and (min-width: 1024px) and (max-height: 896px) {
  .Container {
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1024px) and (min-height: 897px) {
  .Container {
  }
}
@media only screen and (min-width: 1280px) and (min-height: 897px) {
  .Container {
  }
}
