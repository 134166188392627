.LandingPageContainer {
  overflow-x: hidden;
}

.LandingPageContainer::-webkit-scrollbar {
  width: 50px;
}
a {
  text-decoration: none;
}

.HeaderContainer {
  background: #f9f7f1;
  width: 100vw;
  height: auto;
  padding-bottom: 8vh;
}
.Header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.Logo {
  margin-top: 2rem;
  margin-left: 10rem;
  font-size: 1rem;
}
.LogoAbout {
  margin-top: 2rem;
  margin-right: 10rem;
  font-size: 0.75rem;
}
.HeaderDiv {
  display: flex;
  flex-direction: row;
  margin-top: 3.5rem;
}
.HeaderLeft {
  display: flex;
  flex-direction: column;
  margin-left: 10rem;
}
.HeaderTitle {
  font-size: 3rem;
  margin: 0;
  line-height: 3.625rem;
  width: 90%;
}
.HeaderPara {
  font-size: 1rem;
  margin-top: 1rem;
  line-height: 1.625rem;
  width: 85%;
}
.HeaderBtn {
  color: #f9f7f1;
  background: black;
  width: 11.063rem;
  height: 72px;
  font-size: 24px;
  border-radius: 8px;
  outline: none;
  margin-top: 5.625rem;
}
.HeaderBtn:hover {
  cursor: pointer;
}
.HeaderRight {
  display: flex;
  flex-direction: column;
  margin-right: 8.688rem;
}
.Hero {
}

.Part2Container {
  display: flex;
  flex-direction: column;
  padding: 0 9rem;
  justify-content: center;
  align-items: center;
}
.AllyContainer {
  margin-top: 2.5rem;
  width: 100%;
}
.AllyTitle {
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 1rem;
}
.StoryContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.ContributeContainer {
  display: flex;
  background: #171717;
  border-radius: 8px;
  height: 400px;

  justify-content: space-between;
  margin-top: 2.5rem;
}

.ContributeBoxDiv {
}
.ContributeTitle {
  font-weight: normal;
  font-size: 36px;
  line-height: 43px;
  color: #ffffff;
  margin: 80px 212px 4px 94px;
  width: 242px;
  height: 87px;
}
.ContributeDesc {
  width: 271px;
  height: 52px;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  margin-left: 94px;
}
.ContributeBtn {
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  width: 77px;
  height: 37px;
  margin-left: 94px;
  margin-top: 32px;
  align-items: center;
  justify-content: center;
}

.ContributeBtnText {
  font-size: 12px;
  line-height: 40px;
}
.ContributeImg {
  border-radius: 8px;
  width: 100%;
  height: 400px;
}
.ResourceContainer {
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
}
.ResourceBox {
  display: flex;
  justify-content: center;
}
.Footer {
  height: 131px;
  background: #f9f7f1;
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
}
.FooterAllied {
  display: flex;
  align-items: center;
  margin-left: 160px;
  text-align: center;
}
.FooterAlliedText {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
}

.FooterSocial {
  display: flex;
  align-items: center;
  margin-right: 160px;
  margin-top: 12px;
}

.FooterImg {
  margin-right: 10px;
  margin-bottom: 15px;
}

.FooterLinkedIn {
  margin-bottom: 15px;
  width: 60%;
}

/* Responsive design */
@media only screen and (max-width: 767px) {
  .HeaderContainer {
    height: 530px;
    padding-bottom: 0;
  }
  .Header {
    justify-content: space-around;
  }
  .Logo {
    margin-left: 0;
  }
  .LogoAbout {
    margin-right: 0;
  }

  .HeaderDiv {
    display: block;

    margin-top: 2.5rem;
    padding: 0 2rem;
  }
  .HeaderLeft {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
  }
  .HeaderTitle {
    font-size: 2rem;

    text-align: center;

    width: 100%;
  }
  .HeaderPara {
    white-space: normal;
    text-align: center;
    width: 100%;
  }
  .HeaderBtn {
    width: 8rem;
    height: 4rem;
    font-size: 16px;
    outline: none;
    margin-top: 2rem;
  }

  .HeaderRight {
    display: none;
  }

  .Part2Container {
  }
  .AllyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    margin: 2.5rem 0;
  }
  .AllyTitle {
    text-align: center;
  }
  .StoryContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .ContributeContainer {
    display: flex;

    height: 40vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 20px;
  }

  .ContributeBoxDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ContributeTitle {
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #ffffff;
    margin: 0;
    width: 242px;
    height: auto;
    text-align: center;
  }
  .ContributeDesc {
    width: auto;
    height: 52px;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    margin-left: 0;
    margin-top: 1rem;
    padding: 0 1vw;
    text-align: center;
  }
  .ContributeBtn {
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    width: 8rem;
    height: 3rem;
    margin-left: 0;
    margin-top: 32px;
    align-items: center;
    justify-content: center;
  }

  .ContributeBtnText {
    font-size: 16px;
    line-height: 40px;
  }
  .ContributeImg {
    display: none;
  }
  .ResourceContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
  }
  .ResourceBox {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .Footer {
    height: 100px;
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
  }
  .FooterAllied {
    display: flex;
    align-items: center;
    margin-left: 0;
    text-align: center;
  }
  .FooterAlliedText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
  }

  .FooterSocial {
    margin-right: 0;
  }
}
@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .HeaderContainer {
    height: 600px;
    padding-bottom: 0;
  }
  .Header {
    justify-content: space-between;
  }
  .Logo {
    margin-left: 10vw;
  }
  .LogoAbout {
    margin-right: 10vw;
  }
  .HeaderDiv {
    display: flex;
    flex-direction: column;
    margin-top: 3.5rem;
  }
  .HeaderLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    padding: 0 5vw;
  }
  .HeaderTitle {
    margin: 0;
    text-align: center;
    width: 70%;
    line-height: 5rem;
  }
  .Hero {
    display: none;
  }
  .AllyTitle {
    text-align: center;
  }

  .AllyContainer {
    width: 100vw;
  }

  .StoryContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .ContributeContainer {
    display: flex;

    height: 30vh;
    width: 80vw;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .ContributeBoxDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ContributeTitle {
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #ffffff;
    margin: 0;
    width: 242px;
    height: auto;
    text-align: center;
  }
  .ContributeDesc {
    width: auto;
    height: 52px;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    margin-left: 0;
    margin-top: 1rem;
  }
  .ContributeBtn {
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    width: 10rem;
    height: 4rem;
    margin-left: 0;
    margin-top: 32px;
    align-items: center;
    justify-content: center;
  }

  .ContributeBtnText {
    font-size: 20px;
    line-height: 40px;
  }
  .ContributeImg {
    display: none;
  }
  .ResourceContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
  }
  .ResourceBox {
    display: flex;
    justify-content: center;
  }
  .Footer {
    height: 100px;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }
  .FooterAllied {
    display: flex;
    align-items: center;
    margin-left: 0;
    text-align: center;
    margin-left: 10vw;
  }
  .FooterAlliedText {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
  }

  .FooterSocial {
    margin-right: 10vw;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 1024px) {
  .HeaderContainer {
    height: 520px;
  }
  .Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .Logo {
    margin-left: 5rem;
  }
  .LogoAbout {
    margin-right: 5rem;
  }

  .HeaderLeft {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding-left: 5rem;
    width: 50vw;
  }

  .HeaderRight {
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: center;

    width: 50vw;
  }
  .Hero {
    width: 50vw;
    padding-right: 3rem;
  }

  .Part2Container {
    display: flex;
    flex-direction: column;
    padding: 0 8vw;
    justify-content: center;
    align-items: center;
  }
  .AllyContainer {
    margin-top: 2.5rem;
    width: 100%;
  }
  .AllyTitle {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 1rem;
  }
  .StoryContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .ContributeContainer {
    display: flex;
    background: #171717;
    border-radius: 8px;
    height: 400px;
    width: 100vw;
    justify-content: space-between;
    margin-top: 2.5rem;
  }

  .ContributeBoxDiv {
    width: 40vw;
  }
  .ContributeTitle {
    font-weight: normal;
    font-size: 36px;
    line-height: 43px;
    color: #ffffff;
    margin: 80px 212px 4px 94px;
    width: 242px;
    height: 87px;
  }
  .ContributeDesc {
    width: 271px;
    height: 52px;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    margin-left: 94px;
  }
  .ContributeBtn {
    display: flex;
    background: #ffffff;
    border-radius: 8px;
    width: 77px;
    height: 37px;
    margin-left: 94px;
    margin-top: 32px;
    align-items: center;
    justify-content: center;
  }

  .ContributeBtnText {
    font-size: 12px;
    line-height: 40px;
  }
  .ContributeImg {
    border-radius: 8px;

    height: 400px;
  }
  .ResourceContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
  }
  .ResourceBox {
    display: flex;
    justify-content: center;
  }
}
