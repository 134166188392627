.Container {
  background: white;
  box-sizing: border-box;
  position: absolute;
  left: 525px;
  width: 475px;
  z-index: 20;
  height: 100vh;
  box-shadow: 3px 3px 13px -9px rgba(0, 0, 0, 0.96);

  overflow-x: hidden;
}

.ArticleContainer {
  box-sizing: border-box;
  margin: 24px 24px 0 24px;
  width: 425px;
}

.ArticleContainer h2,
.ArticleContainer h3 {
  margin-left: 0px;
}

.ArticleContainer > div:not(:first-child) {
  margin-top: 24px;
}

.HeadingContainer {
  position: relative;
}

.HeadingContainer > h2 {
  padding-right: 50px;
}

.HeadingContainer > .Close {
  position: absolute;
  right: 20px;
  top: 5px;
}

.SubheadingContainer {
  color: var(--primary-color);
  margin-top: 10px !important;
}

.TagsContainer {
  margin-top: 10px !important;
}

.ImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Image {
  width: 100%;
}

.ImagePlaceHolder {
  width: 100%;
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #c4c4c4;
}

.ImagePlaceHolder > * {
  margin-top: 10px;
}

.SummaryContainer {
  font-size: 16px;
}

.SummaryContainer p {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SummaryContainer a {
  display: block;
  margin-bottom: 20px;
  margin-top: 20px;
  color: black;
}

.RelatedStories {
}

.RelatedStories > ul {
}

.RelatedStories li {
  display: block;
  margin-bottom: 8px;
}

.Resources {
  margin-bottom: 64px;
}

.Resources > ul {
}

.Toggled {
  display: block;
}

.Hidden {
  display: none;
}

@media only screen and (max-width: 1023px) and (max-height: 896px) {
  .Container {
    order: 4;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100000;
    height: 100vh;
  }
  .ArticleContainer {
    margin: 0;
    padding: 20px;
    width: 100%;
  }
  .HeadingContainer > .Close {
  }
  .SummaryContainer {
    width: 100%;
  }
}

/* big-tablets */
@media only screen and (max-width: 1023px) and (min-height: 897px) {
  .Container {
    order: 4;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100000;
    height: 100vh;
  }
  .ArticleContainer {
    margin: 0;
    padding: 50px;
    width: 100%;
  }

  .SummaryContainer {
    width: 100%;
  }
  .ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/* Desktops, large screens */
@media only screen and (max-width: 1280px) and (min-width: 1024px) and (max-height: 896px) {
  .Container {
    left: 50vw;
    width: 50vw;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1024px) and (min-height: 897px) {
  .Container {
    width: 50vw;
    padding: 20px;
  }
  .ArticleContainer {
    box-sizing: border-box;
    margin: 50px 24px 0 24px;
    padding: 20px;
  }

  .ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (min-width: 1280px) and (min-height: 897px) {
  .Container {
  }
}
