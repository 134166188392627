.Container {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
}

.TxtContainer {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.TxtContainer > span {
  margin-bottom: 5px;
}

.NameTxt {
  text-decoration: none;
  color: #469fe0;
  font-size: 14px;
  font-weight: 500;
}

.ResourceOwnerTxt {
  font-size: 12px;
  color: var(--primary-color);
}

.ImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  min-height: 55px;
  min-width: 55px;
}
@media only screen and (max-width: 720px) {
  .Container {
  }
}
